import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {rialURL} from '../App.js'


export default function Users() {
 const history = useHistory();


 //const [inputValue, setInputValue] = useState('');


 const [dl,setDl] = useState({});
  const showSwal = () => {
    withReactContent(Swal).fire({
      
      title: "Éxito!",
      text: "Registro Actualizado con Éxito",
      icon: "success"
      
    })
  } 







//Todo esto es para hacer los inputs editables
 const [edif,setEdif] = useState({});
 const handleChange = (event) => {

   var tab = event.currentTarget.getAttribute("name");
   var value = '';
   if(tab =='frecuencia' || tab =='cliente' || tab =='fecha' || tab =='nota' || tab =='estado' || tab =='pago' || tab =='numero'){ //Esto permite que el combobox. Pueda editar información. 
    value = event.currentTarget.value;
   }else{
    value = event.currentTarget.textContent;
   }
   
   var idi = event.currentTarget.id;
   setEdif(values => ({...values, ['val']: value}));
   setEdif(values => ({...values, ['idi']: idi}));
   setEdif(values => ({...values, ['tab']: tab}));
   setEdif(values => ({...values, ['codigo']: 'edUsers'})); //codigo de la tabla a modificar
   //showSwal();
   //console.log(idi);
   
   
 }
 
 useEffect(() => { //Muy importante usar el use efect porque es lo que afecta en tiempo real al DOM
  //console.log(edif);

          //http://localhost:80/chileinmu/api/servicios/update
            //https://appc.codecland.com/api/servicios/ver/
  axios.post(rialURL+'chileinmu/api/servicios/update', edif).then(function(response){
    //console.log(response.data);
    //history.push('/Ver');
});


}, [edif]); 


  
//Borrar Row. 
  const delel = (event) => {
    const cual = event.target.id;

    setDl(values => ({...values, ['cual']: cual}));
    setDl(values => ({...values, ['codigo']: 'Dl_users'}));
    //showSwal();
    window.location.reload();
  }

  useEffect(() => { //Esto evita que no envíe la primera información
  axios.post(rialURL+'chileinmu/api/servicios/borrar', dl).then(function(response){
        console.log(response.data);
    
        
      });
  
  }, [dl]); 







// UseEffect para todos los Combobox
const [client,setClient] = useState([]);
useEffect(() => {
  getClient();
}, []);

//Combobox de Clientes
function getClient() {
  
           //http://localhost:80/chileinmu/api/servicios/ver?cod=ver1
           //https://appc.codecland.com/api/servicios/ver/
  axios.get(rialURL+"chileinmu/api/servicios/ver?cod=users1").then(function (response) {
      console.log(response.data);
      setClient(response.data);
      //history.push('/');
    });
}














  return (
    <div id="sidd" className="content-wrapper">
      

      <section className="content">
        <div className="card">
          
        <div className="card-header " style={{display:"inline-block"}}>
        
          <div style={{float:"left"}}><h1>Usuarios</h1></div>
        
          
        </div>
        <div className="card-body">


        <table id="tb_users" className="table table-bordered table-striped cabesa">
            <thead>
              <tr className="hstiky">
              <th>Nombre Completo</th>
                <th>Usuario</th>
                <th>Contraseña</th>
                <th></th>
              </tr>
            </thead>
            {/* <textarea type="text" className="form-control inpDT" name="n_completo" id={user.id} defaultValue={user.n_completo}  placeholder="" onBlur={handleChange}/>  optionsi.concat({ ididi: user.id})*/}
            <tbody className="noborder">
            {client.map((user, key) =>
              <tr id ="kk" key={key}>
                <td><span contenteditable="true" className="form-control pami" name="n_completo" id={user.id} onBlur={handleChange} >{user.n_completo}</span></td>
                <td><span contenteditable="true" className="form-control pami" name="nombre" id={user.id} onBlur={handleChange} >{user.nombre}</span></td>
                <td><span contenteditable="true" className="form-control pami" name="hash" id={user.id} onBlur={handleChange} >{user.hash}</span></td>
                <td><input type="submit" id={user.id} value ="Borrar" onClick={delel} class="btn btn-primary btnr"/></td>
              </tr>
              )}
                
              </tbody>
            
          </table>
        </div>
        </div>
      </section>
      
    </div>
    
    
  );

  
}