import React, { useEffect } from "react";


var timeout = setTimeout(Footer, 3000);
export default function Footer() {
  
  
  
 

  return (   
<footer className="main-footer">
      <strong>
        Copyright © 20024 <a href="https://codecland.com">Codecland.com</a>.
      </strong>
      <div className="float-right d-none d-sm-inline-block">
        <b>Version</b> 1.0.0
      </div>
    </footer>
  );
  
}
