/*import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;*/

import React from 'react';
import {lazy} from 'react';
import Header from './components/Header';
import Aside from './components/Aside';
import Content from './components/Content';
import Footer from './components/Footer';
import Ver from './components/Ver';
import { BrowserRouter as Router, Route, Switch, Redirect,  } from 'react-router-dom';
import Login from './components/Login';
import Calendar_s from './components/Calendar_s';
import "react-big-calendar/lib/css/react-big-calendar.css" 
import Clientes from './components/Clientes';
import Trabajadores from './components/Trabajadores';
import Trabajos from './components/Trabajos';
import Users from './components/Users';
import Keep from './components/Keep';
import Croq from './components/Croq';
import Ficha from './components/Ficha';
import Excel from './components/Excel';

let redirectToUrl;
let redirectToUrls;
let log = localStorage.getItem("log");
export const rialURL='https://app.chileinmune.cl/';
// https://chile.codecland.com/
// https://app.chileinmune.cl/ 
// http://localhost:80/

if (log !='"activo"') //Si no ha iniciado sesión, lo vuelve al login. 
{
  redirectToUrl = <Redirect to='/login'/>;
}

if (log =='"activo"') //Si la sesión está activa, abre la ventana a ver 
{
  redirectToUrls = <Redirect to={'/Ver/'+new Date().getFullYear()}/>;
}



export default function App() {
  return (
    <Router>

    {redirectToUrl}
      <Switch>

        <Route exact path='/login'>
          <Login />
          {redirectToUrls}
        </Route>
        
        <Route exact path='/contenido'>
          <Header />
          <Aside />
          <Content />
          <Footer />
        </Route>

        <Route exact path='/ver/:cod_u'>
         <Header />
          <Aside />
          <Ver />
          <Footer />
        </Route>

        <Route exact path='/calendario'>
         <Header />
          <Aside />
          <Calendar_s />
          <Footer />
        </Route>

        <Route exact path='/clientes'>
         <Header />
          <Aside />
          <Clientes />
          <Footer />
        </Route>

        <Route exact path='/trabajadores'>
         <Header />
          <Aside />
          <Trabajadores />
          <Footer />
        </Route>

        <Route exact path='/trabajos'>
         <Header />
          <Aside />
          <Trabajos />
          <Footer />
        </Route>

        <Route exact path='/notas'>
         <Header />
          <Aside />
          <Keep />
          <Footer />
        </Route>

        <Route exact path='/croquis'>
         <Header />
          <Aside />
          <Croq />
          <Footer />
        </Route>

        <Route exact path='/ficha'>
         <Header />
          <Aside />
          <Ficha />
          <Footer />
        </Route>

        <Route exact path='/excel'>
         <Header />
          <Aside />
          <Excel />
          <Footer />
        </Route>

        <Route exact path='/usuarios'>
         <Header />
          <Aside />
          <Users />
          <Footer />
        </Route>

      </Switch>
      
      
    
    </Router>
  )
}


