import React from "react";



const logout = (event) => { //Cierra la sesión del usuario
  localStorage.setItem('log', JSON.stringify('inactivo'))
  window.location.reload(); //Recarga en la página. 

}
const reload = (event) => { //Cierra la sesión del usuario
  window.location.reload(); //Recarga en la página. 

}









export default function Header() {
  return (
    <div>
      <div id="preloader" className="preloader flex-column justify-content-center align-items-center">
  <img className src="../dist/img/gi.gif" alt="AdminLTELogo" height={60} width={60} /><label>Cargando...</label>
</div>

    
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" role="button">
            <i className="fas fa-bars" />
          </a>
        </li>
        
      </ul>
      {/* Right navbar links */}
      <ul className="navbar-nav ml-auto">
      <li className="nav-item">
      <a title="Actualizar" className="nav-link" onClick=
        {reload} href="#" role="button"><i className="fas fa-sync-alt"></i></a>
        
      </li>
      <li className="nav-item">
          <a title="Cerrar Sesión"
            className="nav-link"
            data-widget="control-sidebar"
            data-slide="true"
            href="#"
            onClick={logout}
            role="button"
          >
            <i className="fa-solid fa-right-from-bracket"></i>
          </a>
        </li>
        
        
      </ul>
    </nav>
    </div>
  );
  /*
  <li className="nav-item">
          <a title="Pantala Completa"
            className="nav-link"
            data-widget="fullscreen"
            href="#"
            role="button"
          >
            <i className="fas fa-expand-arrows-alt" />
          </a>
        </li>
  */ 
}
