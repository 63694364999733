import React,{useCallback, useEffect, useState} from 'react';
import dayjs from 'dayjs';
import{Calendar, dayjsLocalizer} from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import "dayjs/locale/es";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from "axios";
import {rialURL} from '../App.js'

dayjs.locale("es");

export default function Calendar_s() {
  const localizer = dayjsLocalizer(dayjs);

  const [servi,setServi] = useState([]); //este es un array con la tabla de servicios
  const [l,setl] = useState([]);
  useEffect(() => {
    getServicios();
  }, []);

  function getServicios() {
    
             //http://localhost:80/chileinmu/api/servicios/ver?cod=ver1
             //https://appc.codecland.com/api/servicios/ver/
    axios.get(rialURL+"chileinmu/api/servicios/ver?cod=ver1").then(function (response) {
        console.log(response.data);
        setServi(response.data);
        //history.push('/');
        setl(response.data) ;

        /*response.data.map((el) => (
          console.log(el.id)
        ));*/



      });
  }






  const events = [
    {
        start: dayjs('2024-03-12T12:00:00').toDate(),
        end:dayjs('2024-03-12T12:00:00').toDate(),
        title:"Fumigacion",
        color:"red"
    },
    {
        start: dayjs('2024-03-10T12:00:00').toDate(),
        end:dayjs('2024-03-10T12:00:00').toDate(),
        title:"Desratizacion"
    }
  ];
  console.log(events[0]['start']);

  const messages = {
    allDay: "Todo el día",
    previous: "Anterior",
    next: "Siguiente",
    today: "Hoy",
    month: "Mes",
    week: "Semana",
    day: "Día",
    agenda: "Agenda",
    date: "Fecha",
    time: "Hora",
    event: "Evento",
    noEventsInRange: "Sin eventos"
};

const onSelectEvent = useCallback((calEvent) => {
    
    console.log(calEvent);



   //Este es el menu que muestra la información
    const showSwal = () => {
        //Quitamos las comillas 
        let ti = JSON.stringify(calEvent.fecha).replace(/"/g,'');
        let sr = JSON.stringify(calEvent.title).replace(/"/g,'');
        let cl = JSON.stringify(calEvent.cliente).replace(/"/g,'');
        let pe = JSON.stringify(calEvent.personas).replace(/"/g,'');

        
        switch('undefined, '){
            case ti: ti='Sin Registro';
                

            case sr: sr='Sin Registro';
                

            case cl: cl='Sin Registro';
                

            case pe: pe='Sin Registro';
                
        }
        //console.log(pe);

        withReactContent(Swal).fire({
         // text: JSON.stringify(calEvent.cliente.value),
         icon: "info",
          html:  
          `<section className="content">
          <div className="card">
          <div className="card-header">
              <h2 style={{textAlign:"center"}}>`+ti+`</h2>
              </br>
          <div className="card-body">
            <div>
              <span>Servicios: </span>
              <span>`+sr+`</span>
            </div>
            <div>
              <span>Clientes: </span>
              <span>`+cl+`</span>
            </div>
            <div>
              <span>Trabajadores: </span>
              <span>`+pe+`</span>
            </div>
          </div>
          </div>
          </div>
          </section>`
          
          
        })
      } 


    //Mostramos   
    showSwal();
  }, [])



  return (
    <div id="sidd" className="content-wrapper">
    <section className="content">
    <div className="card">
    <div className="card-header" style={{display:"inline-block"}}>
        <h2 style={{float:"left"}}>Calendario Servicios</h2>
        
        <div style={{float:"right", fontSize: "37px"}}><a href={"/ver/"+new Date().getFullYear()}><i class="fa-solid fa-table"></i></a></div>
    <div><h2>.</h2></div>
<div className="card-body">
    <div style={{
        
        height: "105vh",
        padding:"auto",
        
        width:"auto",
        

    }}>
      <Calendar
      localizer={localizer}
      events={
        servi.map((user, key) =>{

        //Servicios 
           var g = JSON.parse(l[key].servicio)
           var nser = '';
           g.map((user, key) =>{nser +=user.value+','}) 

        //Clientes
        var g2 = JSON.parse(l[key].cliente)
        var nser2 = '';
        g2.map((user, key) =>{nser2 +=user.value+', '}) 
        
        //Personas
        var g3 = JSON.parse(l[key].personas)
        var nser3 = '';
        g3.map((user, key) =>{nser3 +=user.value+', '}) 
           
            return{
              fecha:dayjs(user.fecha).format('DD/MM/YYYY'),
              idix: user.id,
              cliente:nser2,
              personas:nser3,
              start: dayjs(user.fecha).toDate(),
              end:dayjs(user.fecha).toDate(),
              title: nser,
              colorEvento:user.color,
              color:'white'
            } 
        })
      }
      messages={messages}
      onSelectEvent={onSelectEvent}
      eventPropGetter={(myEventsList) => {
        const backgroundColor = myEventsList.colorEvento ? myEventsList.colorEvento : 'blue';
        const color = myEventsList.color ? myEventsList.color : 'blue';
        return { style: { backgroundColor ,color} }
      }}
      
      
      />
      </div>
    </div>
    </div>
    </div>
    </section>
    </div>
    
  )
}
