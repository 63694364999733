import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {rialURL} from '../App.js'


export default function Trabajos() {
 const animatedComponents = makeAnimated();
 const history = useHistory();


 //const [inputValue, setInputValue] = useState('');


 const [dl,setDl] = useState({});
  const showSwal = () => {
    withReactContent(Swal).fire({
      
      title: "Éxito!",
      text: "Registro Actualizado con Éxito",
      icon: "success"
      
    })
  } 














  const [fr,setFR] = useState({});

//Todo esto es para hacer los inputs editables
 const [edif,setEdif] = useState({});
 const [servi,setServi] = useState([]); //este es un array con la tabla de servicios

 const handleChange = (event) => {

   var tab = event.currentTarget.getAttribute("name");
   var value = '';
   if(tab =='frecuencia' || tab =='cliente' || tab =='fecha' || tab =='nota' || tab =='estado' || tab =='pago' || tab =='numero' ){ //Esto permite que el combobox. Pueda editar información. 
    value = event.currentTarget.value;
   }else{
    value = event.currentTarget.textContent;
   }
   
   var idi = event.currentTarget.id;
   setEdif(values => ({...values, ['val']: value}));
   setEdif(values => ({...values, ['idi']: idi}));
   setEdif(values => ({...values, ['tab']: tab}));
   setEdif(values => ({...values, ['codigo']: 'edTRA'}));
   //showSwal();
   console.log(value);
   
   
 }
 
 useEffect(() => { //Muy importante usar el use efect porque es lo que afecta en tiempo real al DOM
  //console.log(edif);

          //http://localhost:80/chileinmu/api/servicios/update
            //https://appc.codecland.com/api/servicios/ver/
  axios.post(rialURL+'chileinmu/api/servicios/update', edif).then(function(response){
    //console.log(response.data);
    //history.push('/Ver');
});


}, [edif]); 





const [l,setl] = useState([]); //Con esto se obtienen. Las frecuencias como un objeto Jason de los servicios 





//Con esto se obtiene la tabla de servicios
  useEffect(() => {
    getServicios();
  }, []);

  function getServicios() {
    
             //http://localhost:80/chileinmu/api/servicios/ver?cod=ver1
             //https://appc.codecland.com/api/servicios/ver/
    axios.get(rialURL+"chileinmu/api/servicios/ver?cod=ver1").then(function (response) {
        console.log(response.data);
        setServi(response.data);
        //history.push('/');
        setl(response.data) ;

        /*response.data.map((el) => (
          console.log(el.id)
        ));*/



      });
  }
//console.log(l[1]['frecuencia']);








  
//Borrar Row. 
  const delel = (event) => {
    const cual = event.target.id;

    setDl(values => ({...values, ['cual']: cual}));
    setDl(values => ({...values, ['codigo']: 'Dl_tra'}));
    //showSwal();
    window.location.reload();
  }

  useEffect(() => { //Esto evita que no envíe la primera información
  axios.post(rialURL+'chileinmu/api/servicios/borrar', dl).then(function(response){
        console.log(response.data);
    
        
      });
  
  }, [dl]); 




//Combobox de Personas
const [per,setPer] = useState([]);
function getPer() {
  
           //http://localhost:80/chileinmu/api/servicios/ver?cod=ver1
           //https://appc.codecland.com/api/servicios/ver/
  axios.get(rialURL+"chileinmu/api/servicios/ver?cod=per1").then(function (response) {
      console.log(response.data);
      setPer(response.data);
      //history.push('/');
    });
}




//Combobox de Servicios
const [ser,setSer] = useState([]);
function getSer() {
  
           //http://localhost:80/chileinmu/api/servicios/ver?cod=ver1
           //https://appc.codecland.com/api/servicios/ver/
  axios.get(rialURL+"chileinmu/api/servicios/ver?cod=ser1").then(function (response) {
      console.log(response.data);
      setSer(response.data);
      //history.push('/');
    });
}






// UseEffect para todos los Combobox
const [client,setClient] = useState([]);
useEffect(() => {
  getClient();
  getSer();
  getPer();
  console.log(servi);
}, []);

//Combobox de Clientes
function getClient() {
  
           //http://localhost:80/chileinmu/api/servicios/ver?cod=ver1
           //https://appc.codecland.com/api/servicios/ver/
  axios.get(rialURL+"chileinmu/api/servicios/ver?cod=cli1").then(function (response) {
      console.log(response.data);
      setClient(response.data);
      //history.push('/');
    });
}













  return (
    <div id="sidd" className="content-wrapper">
      

      <section className="content">
        <div className="card">
          
        <div className="card-header " style={{display:"inline-block"}}>
        
          <div style={{float:"left"}}><h1>Trabajos</h1></div>
        
          
        </div>
        <div className="card-body">


        <table id="tb_trabajos" className="table table-bordered table-striped cabesa">
            <thead>
              <tr className="hstiky">
                <th>Nombre</th>
                <th>Costo Insumo</th>
                <th>Tarifa Mínima</th>
                <th>Tarifa Estandar</th>
                <th>Tarifa Oficial</th>
                <th>Ficha Técnica</th>
                <th></th>
              </tr>
            </thead>
            {/* <textarea type="text" className="form-control inpDT" name="n_completo" id={user.id} defaultValue={user.n_completo}  placeholder="" onBlur={handleChange}/>  optionsi.concat({ ididi: user.id})*/}
            <tbody className="noborder">
            {ser.map((user, key) =>
              <tr id ="kk" key={key}>
                <td><span contenteditable="true" className="form-control pami" name="servicio" id={user.id} onBlur={handleChange} >{user.servicio}</span></td>
                <td><span contenteditable="true" className="form-control pami" name="cost_insumo" id={user.id} onBlur={handleChange}  >{user.cost_insumo}</span></td>
                <td><span contenteditable="true" className="form-control pami" name="t_minima" id={user.id} onBlur={handleChange} >{user.t_minima}</span></td>
                <td><span contenteditable="true" className="form-control pami" name="t_estandar" id={user.id} onBlur={handleChange} >{user.t_estandar}</span></td>
                <td><span contenteditable="true" className="form-control pami" name="cost_tr" id={user.id} onBlur={handleChange} >{user.cost_tr}</span></td>
                <td><a href={user.ficha} target= '_blank'>Ver Ficha</a></td>
                <td><input type="submit" id={user.id} value ="Borrar" onClick={delel} class="btn btn-primary btnr"/></td>
              </tr>
              )}
                
              </tbody>
            
          </table>
        </div>
        </div>
      </section>
      
    </div>
    
    
  );

  
}