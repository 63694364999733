import {React, useState, useEffect} from 'react'
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import axios from "axios";
import {rialURL} from '../App.js'
export default function Keep() {

    const [content, setContent] = useState('');
    const [note,setNote] = useState([]);
    const [not,setNot] = useState([]);
    const [not2,setNot2] = useState([]);
    const [dl,setDl] = useState({});

    const modules = {
        toolbar: [
          [{ 'font': [] }],
          [{ size: [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' },{ 'list': 'check' },
          { 'indent': '-1' }, { 'indent': '+1' }],
          
        ],
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        }
      };

      


  function edidi (iid, text){
    
    setNot(values => ({...values, ['idi']: iid}));
    setNot(values => ({...values, ['tab']: text}));
    setNot(values => ({...values, ['val']: content}));
    setNot(values => ({...values, ['codigo']: 'edNota'}));
console.log(text);
    
  }
  useEffect(() => { //Cuando cambie la variable "dl"
    axios.post(rialURL+'chileinmu/api/servicios/editar', not).then(function(response){
          console.log(response.data);
      
          
        });
    
    }, [not]); 

    const edidi2 = (event) => {

        var tab = event.currentTarget.getAttribute("name");
        var value = '';
        value = event.target.value;
        var idi = event.currentTarget.id;
      
      
        setNot2(values => ({...values, ['val']: value}));
        setNot2(values => ({...values, ['idi']: idi}));
        setNot2(values => ({...values, ['tab']: tab}));
        setNot2(values => ({...values, ['codigo']: 'edNota'}));
       // console.log(value);
        
        
        
      }
      useEffect(() => { //Cuando cambie la variable "dl"
        axios.post(rialURL+'chileinmu/api/servicios/editar', not2).then(function(response){
              //console.log(response.data);
          
              
            });
        
        }, [not2]);





      const crt = (event) => {
        // crear Notas
  axios
  .get(rialURL + "chileinmu/api/guias/ver?crt_Note=crt_Note")
  .then(function (response) {
    console.log(response.data);
  });
  
  window.location.reload();
      }

//    const quill = new Quill('#editor');
useEffect(() => { 
  
    getNotas();
  
  },[]); 


function getNotas() {
axios.get(rialURL+"chileinmu/api/servicios/ver?cod=getNotas").then(function (response) {
//console.log(response.data);
setNote(response.data);

}); 
}

//Borrar Row. 
const delel = (event) => {
  const cual = event.target.id;

  setDl(values => ({...values, ['cual']: cual}));
  setDl(values => ({...values, ['codigo']: 'Dl_nota'}));
  //showSwal();
  window.location.reload();
}

useEffect(() => { //Esto evita que no envíe la primera información
axios.post(rialURL+'chileinmu/api/servicios/borrar', dl).then(function(response){
      console.log(response.data);
  
      
    });

}, [dl]);


  return (
    <div id="sidd" className="content-wrapper ">
        <button onClick={crt} id="sos2" className="sombra2 buton fa-solid fa-plus"> </button>
       <div className="tarj">
{note.map((user, key) =>
    <section key={key} className="content2 arr">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-63">
              <div className="card card-primary">
              <div className="card-header kep">
                
                 <input className="card-title centro nofon" name='titulo' id={user.id} defaultValue={user.titulo} onChange={edidi2} />
                 <i id={user.id} value ="Borrar" onClick={delel} class="fa-solid fa-trash-can btn btn-primary btnr"></i>
                </div>
        <div className="card-body">

        <ReactQuill defaultValue={user.texto} onChange={setContent} tag='texto' displayName='texto' id={user.id} onBlur={() =>edidi(user.id, 'texto')} modules={modules} theme='snow'/>
        
        </div>
              </div>
            </div>
          </div>
        </div>
      </section>
)}
        

        </div> 
    </div>
  )
}
