import React from "react";
import {Link} from 'react-router-dom';

var que = localStorage.getItem('us');
if(que!= null){
  que = que.replace(/"/g,' ');
}



export default function Aside() {
  return (
    <aside id="asi" className="main-sidebar sidebar-dark-primary elevation-4">
      {/* Brand Logo */}
      <a href="#" className="brand-link">
        <img
          src="../dist/img/AdminLTELogo.png"
          alt="AdminLTE Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: ".8" }}
        />
        <span className="brand-text font-weight-light">ChileInmune App!</span>
      </a>
      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar user panel (optional) */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
          <i src="#" className=" fas c fa-user-circle" alt="User Image"></i>
          </div>
          <div className="info">
            <a href="#" className="d-block">
              
              {que}
            </a>
          </div>
          {/* <div className="info">
          <a data-widget="pushmenu" class="fa-solid fa-x"></a>
          </div> */}
        </div>
        {/* SidebarSearch Form */}
        {/* <div className="form-inline">
          <div className="input-group" data-widget="sidebar-search">
            <input
              className="form-control form-control-sidebar"
              type="search"
              placeholder="Buscar"
              aria-label="Search"
            />
            <div className="input-group-append">
              <button className="btn btn-sidebar">
                <i className="fas fa-search fa-fw" />
              </button>
            </div>
          </div>
        </div> */}
        {/* Sidebar Menu */}
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
           // data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
           {/* <li id ="menu_li" className="nav-item">
           <button href="#" className="nav-link v">
                <i className="nav-icon fas fa-copy" />
                <p>
                  ChileInmune
                  <i className="fas fa-angle-left right" />
                  <span className="badge badge-info right">6</span>
                </p>
              </button>
              <ul id="menu" className="nav nav-treeview">
              <li className="nav-item">
                  <a href="/ver" className="nav-link">
                    <i className="far fa-circle nav-icon" />
                    <p>Servicios</p>
                  </a>
                </li>
                
              </ul>
            </li> */}
            {/* {empieza el menu} */}
            <li className="nav-item ">
                  <a href={"/ver/"+new Date().getFullYear()} className="nav-link">
                    <i className="fa-solid fa-house-chimney nav-icon" />
                    <p>Servicios</p>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="/calendario"
                    className="nav-link"
                  >
                    <i className="far fa-regular fa-calendar nav-icon" />
                    <p>Calendario</p>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="/clientes"
                    className="nav-link"
                  >
                    <i className="fa-solid fa-address-book nav-icon" />
                    <p> Clientes</p>
                  </a>
                </li>
                
                <li className="nav-item">
                  <a
                    href="/croquis"
                    className="nav-link"
                  >
                    <i className="fa-solid fa-arrow-up-from-bracket nav-icon" />
                    <p>Subir Croquis</p>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    href="/excel"
                    className="nav-link"
                  >
                    <i className="fa-solid fa-arrow-up-from-bracket nav-icon" />
                    <p>Subir Certificado</p>
                  </a>
                </li>
                
                <li className="nav-item">
                  <a
                    href="/trabajadores"
                    className="nav-link"
                  >
                    <i className="fa-solid fa-user-group nav-icon" />
                    <p>Trabajadores</p>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    href="/trabajos"
                    className="nav-link"
                  >
                    <i className="fa-solid fa-faucet nav-icon" />
                    <p>Tipos de Servicios</p>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    href="/ficha"
                    className="nav-link"
                  >
                    <i className="fa-solid fa-arrow-up-from-bracket nav-icon" />
                    <p>Subir Ficha Técnica</p>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    href="/notas"
                    className="nav-link"
                  >
                    <i className="fa-solid fa-note-sticky nav-icon" />
                    <p>Notas</p>
                  </a>
                </li>
                
                <li className="nav-item">
                  <a
                    href="/usuarios"
                    className="nav-link"
                  >
                    <i className="fa-solid fa-house-chimney-user nav-icon" />
                    <p>Usuarios</p>
                  </a>
                </li>

                

                <li className="nav-item">
                  <a
                    href="#"
                    className="nav-link"
                  >
                    <i className="fa-solid fa-chart-column nav-icon" />
                    <p>Estadistica</p>
                  </a>
                </li>






            
          </ul>
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>
  );
}
