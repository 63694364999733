import React from 'react'
import { useState,useEffect } from "react"; 
import axios from "axios";
import {useHistory} from "react-router-dom";
import {rialURL} from '../App.js'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function Ficha() {
    const [des, setDes] = useState([]); // clientes
    const [inputs,setInputs] = useState({});
    const [file3,setFiles] = useState();
    const [state,setState] = useState({file:null});
    var formData = new FormData();

  // Modal de éxito
  const swal_bien = () => {
    withReactContent(Swal).fire({
      title: "Bien",
      text: "Subido Con Éxito",
      icon: "success",
    });
  };

  const uploadFile = (event) => {
    event.preventDefault();

    let as = document.getElementById('servicios').value;
    let fileInput = document.getElementById('file');

    let fileu = fileInput.files[0];

    formData.append("file_f", fileu);
    formData.append("servi", as);
    setFiles(formData);

    swal_bien();
    
  };

  useEffect(() => {
    axios.post(rialURL+'chileinmu/api/user/save', file3, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        
      });
  }, [file3]);


  // Se llaman las Funciones
  useEffect(() => {
    //Carga todo
    getAll();
  }, []);

  function getAll() {

    // Get Destinos
 axios
 .get(rialURL + "chileinmu/api/guias/ver?cod=ser1")
 .then(function (response) {
   //console.log(response.data);
   setDes(response.data);
 });

 

}

  return (
    <div id='sidd' className="content-wrapper">
       <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Ficha Técnica</h3>
              </div>

              <form onSubmit={uploadFile}>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="nombre">Ficha Técnica</label>
                    <input
                    style={{ maxWidth:'450px'}}
                      type="file"
                      className="form-control"
                      id="file"
                      name="file"
                      placeholder="ingrese destino"
                      required/>
                  </div>
                  <div className="form-group">
                        <label htmlFor="nombre">Servicios: </label>
                        <br/>
                        <select
                          class="form-control selc inputi"
                          defaultValue="0"
                          name="servicios"
                          id="servicios"
                          required>
                          <option value="0">Seleccione Servicio</option>
                          {des.map((u, key) => (
                            <option key={key} value={u.id}>
                              {u.servicio}
                            </option>
                          ))}
                        </select>
                      </div>
                  
                </div>

                <div className="card-footer">
                  <button 
                  id="sos" 
                  className="btn btn-primary">
                    Subir
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}
